.nav-bar {
    position: fixed;
    top: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(147, 141, 194, 0.284);
    backdrop-filter: blur(12px);
    height: 80px;
    padding: 10px 0px;
    z-index: 3;
}

.nav-item {
    margin-right: 60px;
    font-size: 28px;
    font-weight: 500;
    color: white;
    text-decoration: none;
}

@media screen and (max-width: 800px) {
    .nav-item {
        font-size: 24px;
        margin-right: 40px;
    }
}