.projects {
    padding-top: 100px;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: cover;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.professional-work {
    display: flex;
    flex-direction: column;
}

.projects-desc {
    font-size: 24px;
    width: 90%;
}

.proj-cell-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.proj-gif {
    border-radius: 33px;
    max-width: 100%;
    object-fit: contain;
}

.project-buttons {
    margin-right: 10px;
    width: 100px;
    border: 1px solid lightgray;
    background-color: black;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.project-buttons:hover {
    background-color: rgb(213, 212, 212);
    color: black;
}

@media screen and (max-width: 1024px) {
    .proj-gif {
        margin-top: 2rem;
    }
    .proj-cell-content {
        flex-direction: column;
    }
    .projects-desc {
        font-size: 21px;
        width: 100%;
    }
}
