.Home {
    padding-top: 100px;
    background-repeat: no-repeat;
    background-attachment: fixed; 
    background-size: cover;
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.home-intro {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.my-name {
    font-size: 128px;
    font-weight: 700;
    line-height: .9;
}

.my-description {
    font-size: 40px;
    font-weight: 500;
    width: 70%;
}

.home-section-title {
    font-size: 58px;
}

.home-section-desc {
    font-size: 22px;
    font-weight: 500;
    width: 75%;
}

.contact {
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 800px) {
    .home-intro {
        flex-direction: column-reverse;
        text-align: center;
    }
    .my-name {
        font-size: 80px;
    }
    .my-picture {
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .my-description {
        font-size: 32px;
        width: 100%;
    }

    .home-section-desc {
        width: 100%
    }

    .contact {
        flex-direction: column;
    }
}